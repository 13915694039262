.urp-academics-section {
}

.urp-academics-section.urp-profile-section > ion-item > span:nth-child(1) {
  left: -18px;
}

.urp-academics-section .urp-academics-item > div:nth-child(2) {
  margin-left: 16px;
  text-align: right;
}
