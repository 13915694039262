.pending-connection-item {
  border-radius: 10px;
  /* background-color: white;*/
  --background: #f9fbfb;
  border-bottom: 1px solid #ececec;
  padding: 0 10px;
  --padding-start: 0;
  --inner-padding-end: 0;

  ion-row.connection-row {
    width: 100%;
    align-items: center;
  }

  .connection-image-name-container {
    display: flex;
    align-items: center;
  }

  .connection-image-name-container .connection-image {
    width: 31px;
    height: 31px;
  }

  .connection-image-name-container .connection-name {
    margin-left: 1rem;
    margin-right: 1rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .connection-buttons {
    display: flex;
    align-items: center;

    ion-button {
      --background: transparent;
      --color: #00d6b6;
      --padding-start: 6px;
      --padding-end: 6px;
    }

    .sep {
      width: 1px;
      background-color: #ccdbdc;
      height: 20px;
    }
  }
}
