.profile-insights {
  background-color: #f9fbfb;

  .profile-insights-content {
    --background: #f9fbfb;
  }

  .insights-item {
    --padding-end: 20px;
    font-size: 12px;
    --background: #f9fbfb;
  }

  .user {
    &.last {
      --inner-padding-bottom: 16px;
    }

    ion-avatar {
      height: 32px;
      width: 32px;
      margin-right: 16px;
    }

    .insights-label {
      flex: 1;
      font-weight: 500;
      overflow-x: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .insights-value {
      flex: 0.5;
      color: #d6d6d6;
      overflow-x: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .insights-label {
    color: #1a3a35;
  }

  .insights-value {
    color: #1a3a35;
  }

  .insights-header-row {
    --padding-start: 0;
    --padding-end: 0;
    font-size: 12px;
    display: flex;
    --background: #f9fbfb;
  }

  .insights-header-row > div:nth-child(1) {
    padding-left: 16px;
  }

  .insights-header-row > div:nth-child(2) {
    flex: 1;
    padding-right: 16px;
    text-align: right;
  }

  .insights-header-select {
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    padding: 5px 24px 5px 8px;
    position: relative;
  }

  .insights-header-select ion-icon {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
  }

  .insights-toggle {
    width: 10px;
    margin-left: 16px;
  }
}
