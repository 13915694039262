.watchlist-listing-page {
  background-color: #1a3a35;
}

.watchlist-listing-page .feed-header {
  background-color: #1a3a35;
  color: white;
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
  height: 90px;
  background-color: #1a3a35;
}

.watchlist-listing-page .home-header {
  background-color: #1a3a35;
  height: auto;
  --opacity-scale: 0 !important;
}

.watchlist-listing-page .home-header-toolbar {
  --background: #1a3a35;
}

.watchlist-listing-page .home-content {
  --background: #1a3a35;
}

.watchlist-listing-page .home-toolbar {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  --background: #f9fbfb !important;
  position: sticky;
}

.watchlist-listing-page .home-filter {
  margin-right: 20px;
  float: right;
}
.watchlist-listing-page .urp-comments-count {
  top: 8px;
  position: absolute;
  right: 61px;
  font-size: 0.8em;
  color: #00d6b6;
}

.watchlist-listing-page .urp-likes-count {
  top: 8px;
  position: absolute;
  right: 5px;
  font-size: 0.8em;
  color: #00d6b6;
}

.watchlist-listing-page .urp-float-logo {
  text-align: center;
}

.watchlist-listing-page .item-inner {
  border-bottom: none;
}

.watchlist-listing-page .urp-list-background {
  background-color: #f9fbfb;
}

.watchlist-listing-page .floating-button {
  font-size: 90px;
  background-color: #00816d;
}

/* V2 additions*/
.watchlist-listing-page .urp-profile-header-v2 {
  position: absolute;
  top: 125px;
  left: 0;
  right: 0;
  text-align: center;
  padding-left: 68px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 79%,
    rgba(0, 0, 0, 0) 106%
  );
}

.watchlist-listing-page .feed-name {
  font-size: 1.4em;
}

.watchlist-listing-page .urp-header-stars {
}

.watchlist-listing-page .urp-location-pin {
  margin-left: -35px;
}

.watchlist-listing-page .urp-location-pin div {
  color: #3c91e5;
  font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial,
    'Lucida Grande', sans-serif;
  display: inline;
  margin-top: 0px;
  padding-left: 7px;
}

.watchlist-listing-page .urp-social-tops svg {
  margin-left: 5px;
}

.watchlist-listing-page.urp-action-menu {
  top: 33px;
  right: 58px;
}

.urp-post-item-detail-header {
  border-bottom: 1px solid #686363;
  background-color: white;
  border-radius: 15px 15px 0 0;
}

.urp-post-item-detail-header .feed-item {
  margin-top: 0;
  margin-bottom: 0;
  border-radius: 15px;
  background-color: white;
  box-shadow: none;
}

.urp-post-item-detail-header .feed-bottom {
  margin-top: -31px;
}

.watchlist-listing-page .feed-bottom-icons {
  border-top: none;
}

.urp-watchlist-title {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  color: white;
  font-size: 1.7em;
  top: 16px;
}

.watchlist-listing-page .urp-post-item-detail-header {
  height: 70px;
}

.urp-search-widget-placement {
  position: fixed;
  top: 102px;
  left: 72px;
  right: 30px;
  height: 50px;
  /* background-color: red; */
}

.watchlist-listing-page .urp-post-item-detail-header {
  border-bottom: none;
  margin-bottom: -8px;
}

.watchlist-listing-page ion-header {
  height: 157px;
}
