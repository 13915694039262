.post-detail-page {
  background-color: white;

  ion-list {
    background-color: white;
  }

  .urp-post-detail-background {
    margin: 20px;
    margin-bottom: 0;
    padding-bottom: 8px;
    border-bottom: 1px solid #1a3a35;
  }

  .feed-header {
    background-color: #1a3a35;
    color: white;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    height: 90px;
    background-color: #1a3a35;
  }

  .home-header {
    background-color: #1a3a35;
    height: auto;
    --opacity-scale: 0 !important;
  }

  .home-header-toolbar {
    --background: #1a3a35;
  }

  .home-content {
    --background: #1a3a35;
  }

  .home-toolbar {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    --background: #f9fbfb !important;
    position: sticky;
  }

  .home-filter {
    margin-right: 20px;
    float: right;
  }
  .urp-comments-count {
    top: 8px;
    position: absolute;
    right: 61px;
    font-size: 0.8em;
    color: #00d6b6;
  }

  .urp-likes-count {
    top: 8px;
    position: absolute;
    right: 5px;
    font-size: 0.8em;
    color: #00d6b6;
  }

  .urp-float-logo {
    text-align: center;
  }

  .item-inner {
    border-bottom: none;
  }

  .post-detail-page .urp-list-background {
    background-color: #f9fbfb;
  }

  .post-detail-page .floating-button {
    font-size: 4rem;
    position: fixed;
    bottom: 10px;
    right: calc(10px + var(--ion-safe-area-right, 0px));
    z-index: 999;
  }

  /* V2 additions*/
  .post-detail-page .urp-profile-header-v2 {
    position: absolute;
    top: 125px;
    left: 0;
    right: 0;
    text-align: center;
    padding-left: 68px;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 79%,
      rgba(0, 0, 0, 0) 106%
    );
  }

  .post-detail-page .feed-name {
    font-size: 1.4em;
  }

  .post-detail-page .urp-header-stars {
  }

  .post-detail-page .urp-location-pin {
    margin-left: -35px;
  }

  .post-detail-page .urp-location-pin div {
    color: #3c91e5;
    font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial,
      'Lucida Grande', sans-serif;
    display: inline;
    margin-top: 0px;
    padding-left: 7px;
  }

  .post-detail-page .urp-social-tops svg {
    margin-left: 5px;
  }

  .post-detail-page.urp-action-menu {
    top: 33px;
    right: 58px;
  }

  .urp-post-item-detail-header {
    border-bottom: 1px solid #686363;
    background-color: white;
    border-radius: 15px 15px 0 0;
  }

  .urp-post-item-detail-header .feed-item {
    margin-top: 0;
    margin-bottom: 0;
    border-radius: 15px;
    background-color: white;
    box-shadow: none;
  }

  .urp-post-item-detail-header .feed-bottom {
    margin-top: -31px;
  }

  .feed-bottom-icons {
    border-top: none;
  }

  .feed-item-item-content {
    & > ion-col:first-child {
      padding-left: 0;

      .feed-list-feed-image {
        margin-left: 0;
      }
    }

    .feed-content {
      padding-left: 0;
    }
  }
}
