.sign-up-athlete-page {
}

.pdf-container {
  width: 100%;
  height: 50vh; /* Adjust the height as needed */
  padding: 16px;
  box-sizing: border-box;
}

.sign-up-athlete-page ion-item {
  --padding-start: 0;
  --padding-end: 0;
}

form {
  font-size: 2em;
}

ion-toolbar {
  --border-style: none;
  --background: #f8fafa;
}

.profile-picture {
  height: auto;
  margin-top: auto;
  margin-bottom: auto;
  font-size: 0.8rem;
  color: #c4c4c4;
}

.list-ios {
  background-color: #f8fafa;
}

.profile-text-col {
  margin-top: auto;
  margin-bottom: auto;
}

.photo-button-container {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  width: 5em;
  height: 5em;
  --background: transparent;
  --box-shadow: none;
}

.transparent-button {
  font-size: 6em;
}

.urp-section {
  border-radius: 5px !important;
  padding: 10px 16px;
  margin-top: 20px;
  border: none !important;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
  margin-left: 10px !important;
  margin-right: 10px !important;
}

#sign-up-athlete-page {
  /* background-color: #f8fafa; */
}

.urp-content-area {
  --background: #f8fafa;
}

.urp-content-container {
  min-height: 100vh;
  min-width: 400px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.urp-content-area.inner-scroll {
  /* background-color: #f8fafa; */
}

#sign-up-athlete-page {
  padding: 0;
  margin: 0;
  background-color: #f8fafa;
}

/*
:host .ionicon {
    stroke: #1A3A35;
} */

.urp-section-title {
  margin-left: 0.5rem;
  margin-top: auto;
  margin-bottom: auto;
  color: #1a3a35 !important;
}

.urp-step-num {
  border: 1px solid rgba(0, 0, 0, 0.788);
  padding: 4px;
  margin-right: 10px;
  border-radius: 50%;
  width: 31px;
  height: 31px;
  text-align: center;
  float: left;
}

#background-content {
  background-color: #f8fafa;
}

.urp-list-input {
  background-color: #ffffff;
}

.urp-input {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.urp-signup-label {
  font-size: 0.93em;
  font-weight: 500;
}

.textarea-wrapper {
  min-height: 36px !important;
}

.native-textarea.sc-ion-textarea-ios {
  min-height: 36px !important;
}

.label-stacked.sc-ion-label-ios-h {
  font-size: 0.93em;
}

#sign-up-athlete-page ion-item ion-icon {
  color: #1a3a35 !important;
}

ion-select::part(placeholder) {
  font-size: 0.93em;
  color: #ccdbdc;
  opacity: 1;
}

ion-select::part(text) {
  font-size: 0.93em;
  color: rgb(0, 0, 0);
  opacity: 1;
}

.sc-ion-label-md-h.sc-ion-label-md-s.label-stacked {
  padding-left: 5px;
  font-size: 1.3em;
  color: #1a3a35;
  margin-bottom: 15px;
}

.urp-section-head {
  border-bottom: none;
  --inner-padding-end: 0;
  --inner-padding-start: 0;
}

.urp-green-btn {
  display: block;
  width: 85%;
  color: '#FFFFFF';
  background: rgb(0, 129, 109);
  background: linear-gradient(90deg, rgba(0, 129, 109, 1) 21%, #90ded2 51%, rgb(9 180 155) 110%);
  color: #ffffff;
  text-transform: capitalize;
  font-size: 1.1em;
  border-radius: 6px;
  margin-left: auto;
  margin-right: auto;
}

.urp-upload-btn {
  border: 1px dashed #00d6b6;
  background: linear-gradient(90deg, #e0faf6 21%, #e0faf6 51%, #00d6b659 110%);
  text-transform: capitalize;
  border-radius: 6px;
  display: block;
  font-size: 2em;
  font-weight: bold;
  height: 5vh;
  width: 100%;
  color: #00d6b6;
  padding: 2px;
  margin-left: 0;
  margin-right: 0;
}

div.urp-upload-text-signup {
  font-size: 1.1em;
  color: #395450;
  font-weight: 500;
  padding-left: 5px;
  padding-top: 14px;
}

.button-native {
  display: none;
}

.list-md.list-inset ion-item:first-child {
  --border-radius: 2px 2px 0 0;
  --border-width: 0 0 0 0;
}

.urp-blank {
  color: rgba(212, 212, 212, 0);
  position: relative;
  height: 0px;
}

::placeholder {
  color: #02816d !important;
  padding-left: 10px;
  font-weight: bold;
  font-size: 0.92em;
}

.urp-optional {
  color: #d6d6d6;
  padding-left: 8px;
  font-size: 0.8em;
}

.disclaimer-text {
  color: #d6d6d6;
  padding-left: 8px;
  font-size: 0.9em;
  font-weight: 400;
}

.urp-rating-input {
  height: 2.5rem;
  padding-top: 0.6rem;
  margin-bottom: 0.3rem;
}

.urp-rating-label {
  padding-top: 6px;
}

.urp-select-short {
  margin-right: 10%;
  width: 85% !important;
}

.xurp-select {
  margin-top: 1rem;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 10px;
  border: 1px solid rgba(100, 100, 100, 0.1);
  width: 95%;
  padding: 8px;
  border-radius: 6px;
  /* -webkit-box-shadow: 0px 0px 12px 0px rgb(0 0 0 / 15%); */
  -webkit-box-shadow: 2px 3px 5px 1px rgb(0 0 0 / 5%);
  color: #148f7c;

  /* background-image:
    linear-gradient(45deg, transparent 70%, white 50%),
    linear-gradient(135deg, rgb(255, 255, 255) 50%, transparent 50%),
    linear-gradient(to bottom, #148f7c, #ffffff);
    background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    100% 0;
  background-size:
    5px 5px,
    5px 5px,
    2.5em 2.5em;
  background-repeat: no-repeat; */
}

.urp-header-progress {
  background-color: transparent;
  position: absolute;
  z-index: 100000;
  margin-bottom: 20px;
  margin-top: 0px;
  left: 32px;
  top: 0;
  /* margin-left:-30px; */
}

.urp-select-half {
  width: 28vw !important;
}

.xurp-select-half {
  border: 1px solid rgba(100, 100, 100, 0.1);
  margin-right: 10px;
  margin-left: 0;
  margin-bottom: 10px;
  padding: 7px;
  padding-left: 10px;
  border-radius: 6px;
  /* -webkit-box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 10%); */
  -webkit-box-shadow: 2px 3px 5px 1px rgb(0 0 0 / 5%);
  color: #148f7c;
}

.urp-2-col {
  text-align: left;
}

div.select-icon {
  top: 10px;
}

.urp-nav-text {
  padding-top: 6px;
  font-weight: 500;
  margin-left: 6px;
  text-transform: capitalize;
  color: #1a3a35;
  font-size: 1.1em;
}

.urp-upload-photo {
  color: #00d6b6;
  font-size: 0.8rem;
}

.fa-star {
  height: 51px;
  width: 51px;
  fill: #c4c4c4;
}

.fa-star.checked {
  fill: #00d6b6;
}

.required:after {
  content: ' *';
  color: red;
}

.urp-file-upload.video {
  opacity: 0;
  position: absolute;
  background-color: #c3f6ed;
  left: 0;
  top: 28px;
  left: 0;
  right: 0;
  z-index: 100000;
}

.urp-highlight-video-container {
  width: 100%;
}

.urp-highlight-video {
  width: 100%;
  border: 0;
  border-radius: 5px;
}

.urp-file-upload.transcripts {
  opacity: 0;
  position: absolute;
  top: 28px;
  left: 0;
  right: 0;
  z-index: 100000;
}

#sign-up-athlete-page .urp-image-cropper {
  position: absolute;
  top: 1px;
  left: 1px;
  right: 1px;
  bottom: 1px;
  background-color: rgba(20, 23, 20, 0.858);
  z-index: 100000;
  /* border-radius: 20px; */
  overflow: hidden;
}

.camera-footer {
  position: relative;
  color: #1a3a35;
  background-color: rgb(209 209 209) !important;
  height: var(--footer-height);
}

element.style {
}

#sign-up-athlete-page .urp-image-cropper span:nth-of-type(1) {
  position: absolute;
  font-weight: bolder;
  top: 10px;
  left: 10px;
  z-index: 100000;
}

.cropper-view-box {
  display: block;
  height: 100%;
  /* outline:80px solid #00d6b6 !important; */
  /* outline-color: #00d6b6 !important; */
  overflow: hidden;
  width: 100%;
  margin-left: -10px;
  position: absolute;
}

/* close cropper*/
#sign-up-athlete-page .urp-image-cropper span:nth-of-type(1) {
  position: absolute;
  font-weight: bolder;
  top: 10px;
  left: 10px;
  z-index: 100000;
}

#sign-up-athlete-page .urp-image-cropper span:nth-of-type(2) {
  position: absolute;
  top: 30px;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 100000;
}

.cropper-bg {
  background-image: none !important;
}

.cropper-modal {
  background-color: #fff !important;
  opacity: 1 !important;
}

.urp-profile-pic-cropper .cropper-view-box {
  border-radius: 50%;
}

/* Done Button */
#sign-up-athlete-page .urp-image-cropper span:nth-of-type(3) {
  position: absolute;
  bottom: 100px;
  left: 20px;
  right: 20px;
  font-size: 1.5em;
  text-align: center;
  z-index: 10000;
  color: #00d6b6;
}

.urp-spinner-container {
  display: none;
  height: 100%;
  position: fixed;
  z-index: 1;
  width: 100%;
  align-items: center;
  background-color: white;
  opacity: 0.7;
}

.urp-spinner-container.active {
  display: flex;
}

.urp-spinner {
  --color: #a4fefc;
  transform: scale(3);
  margin-right: auto;
  margin-left: auto;
}

.input-padding .select-interface-option > ion-label {
  padding-left: 10px;
}

.urp-preview-profile-pic {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: contain !important;
}

.urp-banner-pic-previewer {
  /* width: 100vw; */
  width: calc(100vw - 62px);
  background: red;
  max-height: 116px;
  max-width: 375px;
  height: calc(1vw * 30);
  border: 1px dashed#00d6b6;
  /* outline:4px  dashed#00d6b6; */
  border-radius: 6px;
  background-size: cover !important;
  background-repeat: no-repeat !important;

  color: #00d6b6;
  text-transform: capitalize;
  font-size: 2.1em;
  font-weight: 900;
  text-align: center;
  padding-top: 43px;
}

.swipe-icon {
  width: 100px;
}

.swipe-icon-container {
  position: absolute;
  z-index: 10000;
  text-align: center;
  left: 0;
  right: 0;
  top: 94px;
}

.urp-crop-header-text {
  color: rgb(255 255 255);
  background-color: #1f1f1f;
  height: 50px;
  margin-top: -30px;
  padding-top: 15px;
}
.urp-crop-header-text.desktop {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
}

.swipe-text {
  color: #00d6b6;
  margin: auto;
}

.urp-section-group {
  min-height: 120vh;
}

/* **************** DESKTOP STYLES **************** */

/* Desktop header & toolbar */
#sign-up-athlete-page .header-desktop,
#sign-up-athlete-page .toolbar-desktop,
#sign-up-athlete-page .square-logo-desktop {
  display: none;
}

@media only screen and (min-width: 600px) {
  #sign-up-athlete-page .header-desktop,
  #sign-up-athlete-page .toolbar-desktop,
  #sign-up-athlete-page .square-logo-desktop {
    display: block;
  }

  #sign-up-athlete-page .urp-section-group {
    margin-top: 10px;
  }

  #sign-up-athlete-page .header-desktop {
    margin: 0;
    width: 100%;
    background-color: #fff;
    color: #398175;
    height: 89px;
    left: 0px;
    top: 0px;
    background: #ffffff;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.07);
    backdrop-filter: blur(2px);
    z-index: 100000;
    text-align: center;
  }

  #sign-up-athlete-page .toolbar-desktop {
    width: 100%;
    height: 80px;
    /* border: 1px solid rgb(227, 225, 225); */
    padding-top: 10px;
  }

  #sign-up-athlete-page .square-logo-desktop {
    position: absolute;
    top: 10px;
    left: 90px;
    width: 60px;
    height: 60px;
    z-index: 1000000;
  }

  #sign-up-athlete-page .toolbar-desktop .progress-desktop {
    position: absolute;
    left: 11px;
    right: 8px;
    height: 70px;
    color: #398175;
    font-size: 18px;
  }

  #sign-up-athlete-page .bar-desktop {
    position: absolute;
    width: 100%;
    top: 30px;
    background-color: rgb(235, 235, 235);
    height: 10px;
    border-radius: 10px;
  }

  #sign-up-athlete-page .bar-value-desktop {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #398175;
    height: 10px;
    border-radius: 10px;
  }

  #sign-up-athlete-page .bar-label-desktop {
    font-weight: 500;
  }

  #sign-up-athlete-page .bar-steps-desktop {
    position: absolute;
    top: 50px;
    left: 0;
  }

  #sign-up-athlete-page .toolbar-no-border {
    display: none;
  }

  /* DESKTOP BUTTONS */
  #sign-up-athlete-page .urp-upload-btn {
    /* display:none !important; */
    box-shadow: none !important;
    padding-top: 0 !important;
    padding-bottom: 5px !important;
  }
}

/* @media only screen and (max-width: 600px) {
  #sign-up-athlete-page .square-logo-desktop {
    left: 10px;
  }

} */

/* -------- CROPPER FIXES ---------- */
.cropper-container {
  width: 100% !important;
  height: auto !important;
  padding-bottom: 66.66% !important;
}

.cropper-crop-box {
  top: 0 !important;
  transform: none !important;
}

.cropper-wrap-box {
  display: none;
}

#sign-up-athlete-page
  > div.urp-image-cropper
  > div
  > div
  > div.cropper-drag-box.cropper-move.cropper-modal {
  z-index: 1000000;
  opacity: 0 !important;
}

#sign-up-athlete-page > div.urp-image-cropper > div > div {
  top: 200px !important;
}

#sign-up-athlete-page > div.urp-image-cropper.desktop > div > div {
  top: -182px !important;
}

#sign-up-athlete-page > div.urp-image-cropper > div {
  height: 100%;
  background-color: white;
  height: 60%;
  text-align: center;
  border-radius: 7px;
  margin-left: 2px;
  margin-right: 2px;
  overflow: hidden;
  border: 1px solid white;
}

#sign-up-athlete-page > div.urp-image-cropper > div > div > div.cropper-crop-box {
  margin: auto !important;
}

#sign-up-athlete-page > div.urp-image-cropper.desktop > div > div > div.cropper-crop-box {
  margin: auto !important;
}

#sign-up-athlete-page > div.urp-image-cropper.desktop-banner > div > div > div.cropper-crop-box {
  margin: auto !important;
  transform: translateY(-200px) !important;
}

.urp-custom-progress-bar {
  position: absolute;
  width: 100%;
  top: 5px;
  left: 0;
  z-index: 100000;
}

.urp-progress-label {
  position: absolute;
  left: 32px;
  right: 92.33%;
  top: 0px;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.75em;
  line-height: 12px;
  width: 150px;
  color: #00816d;
}

.urp-progress-graphic {
  height: 6px;
  background: #dce1e3;
  border-radius: 5px;
  border: none;
  position: absolute;
  top: 16px;
  left: 32px;
  right: 30px;
  overflow: hidden;
}

.urp-progress-graphic div {
  height: 6px;
  background: rgb(57, 129, 117);
  border-radius: 5px;
}

.urp-progress-count {
  position: absolute;
  left: 32px;
  right: 92.33%;
  top: 25px;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.75em;
  line-height: 12px;
  width: 150px;
  color: #00816d;
}

#sign-up-athlete-page .urp-signup-tab-menu-item {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 6px;
  padding-left: 10px;
  padding-right: 10px;
  color: #00816d;
  font-weight: 500;
  background-color: #ffffff;
  word-wrap: normal;
  text-align: center;
  font-size: 0.6rem;
  border-radius: 8px !important;
  border: 1px solid #9bc9c1;
}

#sign-up-athlete-page .urp-stats-add-btn {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

#sign-up-athlete-page .urp-stats-remove-btn {
  padding: 5px 0px 0px;
  text-decoration-line: underline;
  font-size: 12px;
}

.stats-section {
  .section-header {
    ion-grid {
      padding-left: 0;
      padding-right: 0;

      ion-row {
        ion-col {
          padding-left: 0;
          display: flex;
          align-items: center;

          .urp-select {
            border: 1px solid #1a3a35;
            height: 40px;
            border-radius: 20px;
            width: fit-content;

            .urp-select-container {
              padding: 10px 16px;

              .urp-select-chevron {
                margin-left: 16px;
              }
            }
          }

          .add-season {
            height: 20px;
            width: 20px;
            margin-left: 8px;
          }
        }
      }
    }
  }

  .stats-season-text {
    ion-grid {
      padding-left: 0;
      padding-right: 0;

      ion-row {
        ion-col {
          &.header {
            font-size: 15px;
            color: #1a3a35;
            font-weight: 500;
          }

          &.subtext {
            color: #c4c4c4;
            font-size: 12px;
          }
        }
      }
    }
  }

  .stats-category {
    .stats-category-name {
      font-size: 15px;
      padding-left: 12px;
    }

    .stats-category-remove {
      text-align: right;
      color: #00d6b6;
      text-decoration: underline;
      font-size: 12px;
      margin-right: 0;
    }
  }

  .stats-entry {
    ion-grid {
      padding: 0;
      margin-bottom: 20px;

      ion-row {
        display: flex;
        align-items: center;

        ion-col {
          padding-left: 0;
          padding-right: 0;

          .stats-entry-label {
            font-size: 15px;
            font-weight: 500;
          }

          .stats-entry-input {
            font-size: 15px;
            border-bottom: 1px solid #9bc9c1;
          }
        }
      }
    }
  }

  .add-category {
    display: flex;
    align-items: center;
    font-size: 12px;

    span {
      flex: 1;
      text-align: right;
      color: #00d6b6;
      text-decoration: underline;
    }

    img {
      height: 20px;
      width: 20px;
      margin-left: 8px;
    }
  }
}
