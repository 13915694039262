.home-page-athlete-profile .profile-stats-list ion-item > div:nth-child(1) {
  flex: 0;
  flex: initial;
}

.home-page-athlete-profile .profile-stats-list ion-item > div:nth-child(1) svg {
  display: block;
}

.home-page-athlete-profile .profile-stats-list ion-item > div:nth-child(2) {
  color: #00816d;
  font-weight: 300;
  font-size: 12px;
  position: initial;
  top: initial;
  left: initial;
  flex: 1;
  margin-left: 16px;
}

.home-page-athlete-profile .profile-stats-list > ion-item > div:nth-child(3) {
  color: #1a3a35;
  font-weight: 400;
  font-size: 15px;
  position: initial;
  top: initial;
  left: initial;
}
