.feed-comment {
  margin-left: 20px;
  margin-right: 20px;
  padding: 6px 0 0 0;

  ion-label {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  ion-col h2 {
    font-weight: 600;
    margin: 0;
  }

  .feed-list-urp-badge-line {
    display: flex;
    align-items: flex-end;
    gap: 5%;
    font-weight: bold;
    font-size: 1rem;
    margin-top: 0 !important;
  }

  .feed-list-feed-image {
    margin-top: 0px;
    margin-left: 10px;
    margin-right: 10px;
    width: 30px;
    height: 30px;
  }

  .feed-list-feed-name {
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0;
  }

  .feed-list-date {
    font-size: 12px;
    font-weight: 300;
    color: rgb(0, 0, 0);
  }

  .feed-list-feed-message {
    color: #1a3a35;
    font-size: 12px;
    margin-top: 6px;
    margin-bottom: 3px;
    z-index: 100000;
  }
}

@media only screen and (min-width: 1000px) {
  .feed-comment {
    margin-left: auto;
    margin-right: auto;
    max-width: 60%;
  }
}
