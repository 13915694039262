ion-content.connections-content {
  --background: #f9fbfb;

  .connections-search-container {
    padding: 16px 20px;
    width: 100%;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;

    .connections-search-col {
      //filter: drop-shadow(0px 0px 10px rgba(26, 58, 53, 0.05));
      //background-color: white;
      position: relative;
      margin-left: auto;
      margin-right: auto;

      .connections-search {
        font-size: 10px;
        --padding-start: 34px;
        background: white;
        border-radius: 3px;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);

        input {
          &::placeholder {
            padding-left: 0;
          }
        }
      }

      ion-icon {
        position: absolute;
        z-index: 1000;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
        height: 16px;
        width: 15px;
        color: #00d6b6;
        cursor: pointer;
      }
    }

    .connections-sort {
      padding-top: 20px;
    }
  }

  .pending-connections-container {
    width: 500px;
    margin-left: auto;
    margin-right: auto;
  }

  .pending-connections-container ion-col {
    display: flex;
    padding: 1rem 20px 0;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
  }

  .connection-requests-container {
    font-size: 12px;
    padding: 1rem 20px 0;
    width: 500px;
    margin-left: auto;
    margin-right: auto;

    .connection-requests-view-all {
      color: #00d6b6;
      text-decoration: underline;
      margin-left: 1rem;
    }
  }

  .connections-container {
    cursor: pointer;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
}
