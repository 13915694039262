.selected {
  --background: #f7f8fa;
}

.primary-sport-sub-header {
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;
  margin-top: 5rem;
  margin-bottom: 1.5rem;
  color: #000000;
  text-align: left;
  padding-left: 2rem;
}

.primary-sport-sub-label {
  display: block;
  text-align: left;
  font-size: 0.7rem;
  width: 80%;
  padding-left: 2rem;
  color: rgba(155, 201, 193, 1);
}

.sport-button.disabled {
  --background: #c4c4c4;
  color: white;
}

.sport-button {
  --background: #c4c4c4;
  color: white;
}

.primary-sport-input {
  border-radius: 5px;
  padding-bottom: 1px;
}

.primary-sport-label {
  padding-left: 10px;
}
