.follow-button {
  position: relative;

  &.open {
  }

  ion-button {
    display: flex;
    font-size: 10px;
    width: 80px;
    height: 25px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
    border-radius: 3px;

    &.follow-button-green {
      background-color: #00d6b6;
      --background: #00d6b6;
      color: white;

      & > div:nth-child(2) {
        display: none;
      }
    }

    &.follow-button-white {
      background-color: white;
      --background: white;
      color: #1a3a35;
    }

    & > div:first-child {
      flex: 1;
    }

    ion-icon {
      color: #3c91e6;
    }
  }

  .follow-menu {
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    min-width: 100%;
    transform: translateY(100%);
    background: white;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    padding: 0 10px 10px 10px;
    z-index: 100;

    &.open {
      display: block;
    }

    .follow-menu-item {
      display: flex;
      align-items: center;
      font-size: 10px;
      padding-top: 10px;
      white-space: nowrap;

      &.disabled {
        color: silver;
      }

      & > div:first-child {
        flex: 1;
      }
    }
  }
}
