.explore-list {
  background-color: #1a3a35;
}

.explore-list .feed-header {
  background-color: #1a3a35;
  color: white;
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
  height: 90px;
  background-color: #1a3a35;
}

.explore-list .home-header {
  background-color: #ffffff;
  box-shadow: 0px 2px 6px -1px rgb(0 0 0 / 23%);
  height: auto;
  min-height: 5rem;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  --opacity-scale: 0 !important;
}

.explore-list .home-header-toolbar {
  --background: #ffffff;
}

.explore-list-page {
  --background: #f9fbfb;
}

.explore-list-item-content {
  width: 100%;
}

#atBottomOfFeedList {
  border-top: 1px solid #1a3a35;
  margin-top: 8px;
}

#atBottomOfFeedList ion-col {
  color: #c4c4c4;
}
