.post-detail-likes-page {
  background-color: white;

  .post-detail-likes-top {
    --padding-start: 16px;
    --inner-padding-end: 0;
    --padding-end: 16px;
    --padding-top: 16px;
    --padding-bottom: 8px;

    ion-row {
      width: 100%;
    }

    .like-count-container {
      width: 100%;

      .like-count {
        display: flex;
        align-items: center;
        color: #00d6b6;
        margin-right: auto;
        margin-left: auto;
        justify-content: center;
        font-size: 18px;
        font-weight: 400;
      }
    }

    .like-label-container {
      text-align: center;
      color: #1a3a35;
      font-size: 12px;
    }
  }

  ion-list {
    background-color: white;
  }
}
