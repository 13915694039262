ion-modal.remove-highlight-modal {
  ion-item ion-label {
    padding: 15px 0px;
  }
  ion-item:last-child {
    --border-width: 0 0 0 0 !important;
  }
}

.urp-profile-section-mobile {
  @media (min-width: 1088px) {
    height: 80vh !important;
  }
}
