.urp-offers-section {
}

.logo-logo {
  margin-top: 1rem;
}

#urp-offers-section div.urp-offer-item {
  width: 33%;
}
