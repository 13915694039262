.profile-stats-section {
  .stats-header {
    --padding-end: 20px;

    ion-row {
      width: 100%;

      ion-col {
        .stats-category-selection {
          margin-left: auto;
          width: 125px;
          display: flex;
          align-items: center;
          font-size: 12px;
          background: white;
          box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
          border-radius: 4px;
          padding: 5px 10px;

          span {
            flex: 1;
            text-align: center;
            font-weight: 500;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }

          ion-icon {
            width: 5px;
          }
        }
      }
    }
  }

  .stats-table-item {
    position: relative;
    --padding-start: 20px;
    --inner-padding-start: 0px;
    --padding-end: 20px;
    --inner-padding-end: 0px;

    --fade-width: 50px;

    .fade {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: var(--fade-width);
      z-index: 100;
      background-image: linear-gradient(to right, transparent 0%, white 50%, white 100%);
    }

    .no-table {
      font-size: 15px;
      color: red;
    }

    .stats-table-container {
      --first-col-width: 95px;

      width: 100%;
      overflow-x: auto;
      padding-left: var(--first-col-width);
      padding-right: calc(var(--fade-width) * 0.6);
      padding-bottom: 6px;

      .stats-table {
        font-size: 12px;
        width: 100%;

        tr {
          font-size: 12px;

          th:first-child,
          td:first-child {
            background-color: white;
            border-top: 1px solid #ccdbdc;
            border-right: 1px solid #ccdbdc;
            position: absolute;
            left: 0;
            width: var(--first-col-width);
            top: auto;
          }

          &.stats-table-header {
            th {
              border-top: 1px solid #ccdbdc;
              border-bottom: 1px solid #ccdbdc;
              font-weight: 500;
            }
          }

          th,
          td {
            white-space: nowrap;
            padding: 6px;
            text-align: center;
          }
        }
      }
    }
  }
}
