.messaging-page {
  width: 100%;

  .search-item {
    --padding-start: 0px;
    --inner-padding-start: 0px;
    --padding-end: 0px;
    --inner-padding-end: 0px;
    --background: #f9fbfb;
  }
  .search-container {
    padding: 16px 20px;
    width: 100%;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;

    .search-col {
      //filter: drop-shadow(0px 0px 10px rgba(26, 58, 53, 0.05));
      //background-color: white;
      position: relative;
      margin-left: auto;
      margin-right: auto;

      .search {
        font-size: 10px;
        --padding-start: 34px;
        background: white;
        border-radius: 3px;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);

        input {
          &::placeholder {
            padding-left: 0;
          }
        }
      }

      ion-icon {
        position: absolute;
        z-index: 1000;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
        height: 16px;
        width: 15px;
        color: #00d6b6;
      }
    }
  }

  .messaging-content {
    --background: #f9fbfb;

    .conversation-list {
      max-width: 500px;
      margin-left: auto;
      margin-right: auto;
    }

    .filter-container {
      margin-left: auto;
      margin-right: auto;
      width: 40%;
      max-width: 500px;
      margin-top: 10px;
      margin-bottom: -10px;
    }

    .messaging-item {
      --padding-start: 0;
      --inner-padding-start: 20px;
      --inner-padding-end: 20px;
      font-size: 10px;

      ion-row {
        width: 100%;

        ion-col {
          position: relative;
          display: flex;
          align-items: center;
          padding-top: 8px;
          padding-bottom: 8px;

          ion-avatar {
            margin-top: 10px;
            height: 40px;
            width: 40px;
          }

          .group-avatar {
            height: 32px;
            width: 32px;
            background-color: #00816d;
            border-radius: 16px;
            position: relative;
            font-size: 16px;
            font-weight: 500;
            line-height: 1rem;
            color: white;

            & > div {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }

          .name-text-date-unread {
            flex: 1;
            margin-left: 16px;

            .name-unread {
              display: flex;
              align-items: center;
              margin-top: 4px;
              margin-bottom: 4px;
              max-width: calc(100vw - 20px - 32px - 16px - 20px - 12px);

              .name {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                flex: 1;
                font-size: 14px;
                font-weight: 500;
              }

              .unread {
                margin-left: 16px;
                .unread-dot {
                  background-color: #00d6b6;
                  height: 8px;
                  width: 8px;
                  border-radius: 4px;
                }
              }
            }

            .text-date {
              display: flex;
              align-items: center;
              max-width: calc(100vw - 20px - 32px - 16px - 20px - 12px);
              width: fit-content;

              .text {
                font-size: 12px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                flex: 1;

                &.unread {
                  font-weight: 500;
                }
              }

              .date {
                color: #d6d6d6;
                font-size: 12px;
                margin-left: 8px;

                &:before {
                  content: '\2022';
                  margin-right: 4px;
                }
              }
            }
          }
        }
      }
    }
  }

  .floating-button {
    font-size: 4rem;
    position: fixed;
    bottom: 10px;
    right: calc(10px + var(--ion-safe-area-right, 0px));
    z-index: 999;
  }
}

.ion-page-invisible {
  opacity: 1 !important;
}
