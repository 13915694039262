.urp-praise-section {
  .urp-praise-item {
    --padding-start: 20px;
    --inner-padding-start: 0;
    --padding-end: 20px;
    --inner-padding-end: 0;
    margin-bottom: 8px;

    ion-row {
      width: 100%;

      ion-col {
        img {
          height: 48px;
          width: 48px;
          border-radius: 24px;
          display: block;
        }

        .name {
          font-weight: 500;
          font-size: 15px;
          color: #1a3a35;
        }

        .message {
          font-size: 12px;
          color: #1a3a35;
          margin: 10px 0;
        }

        .date {
          font-size: 12px;
          color: #1a3a35;
        }
      }
    }
  }
}
