.game-schedule-add-container {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 20px 0;
}

.game-schedule-add-container-btn {
  display: flex;
  width: 30%;
  align-items: center;
}

.game-schedule-text {
  padding: 0;
}

ion-datetime-button {
  margin-left: auto;
  margin-right: auto;
}

button {
  padding-left: 100px;
}

#date-button {
  padding-left: 100px;
}
