.qr-code-container {
  /*  color: #00D6B6;*/
  font-size: 30px;
  z-index: 11000;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.qr-code {
  padding: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}

.qr-code-urp-float-logo {
  margin-top: 3rem;
}
