.urp-label {
  color: #9bc9c1 !important;
  padding-left: 10px;
}

.logo-image-margin {
  margin-top: 3rem;
}

.urp-grad-button {
  background: rgb(0, 129, 109);
  background: linear-gradient(
    90deg,
    rgba(0, 129, 109, 1) 17%,
    rgba(255, 255, 255, 0.7338644930628502) 53%,
    rgba(0, 132, 113, 1) 96%
  );
}

.urp-center {
  margin-left: auto;
  margin-right: auto;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a:active {
  text-decoration: underline;
}

.header-line {
  border-bottom: 5px solid rgb(0, 129, 109);
}

.urp-sub-header {
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 36px;
  margin-top: 4rem;
  margin-bottom: 1.5rem;
  color: #000000;
}

.urp-nav-bar {
  height: 3.4rem;
}

.urp-nav-btn {
  width: 60px;
}

.urp-arched-header {
  width: 50px;
  height: 50px;
  background-color: #1a3a35;
  z-index: 1000000000;
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
}

.urp-arched-header .urp-arch {
  background-color: white;
  width: 100px;
  height: 23px;
  width: 100%;
  position: absolute;
  top: 32px;
  border-radius: 20px 20px 0 0;
  margin-right: 5px;
}

.urp-indicators {
  height: 23px;
  width: 30%;
  position: absolute;
  top: -13px;
  right: 5px;
  margin-right: 5px;
  z-index: 100;
  text-align: right;
}

.alert-icon {
  position: absolute;
  top: 60px;
  right: 100px;
}

.settings-icon {
  position: absolute;
  top: 61px;
  right: 20px;
}

.urp-time {
  position: absolute;
  top: 14px;
}

/* Desktop Styling */
.tab-bar-logo {
  /*display: none;*/
}

@media (min-width: 562px) {
  .urp-nav-bar.desktop .tab-bar-logo {
    /*display: block;*/
  }
}

.loading-indicator {
  --spinner-color: #a4fefc;
  --background: transparent;
}

.loading-indicator ion-spinner {
  --color: #a4fefc;
  transform: scale(3);
  margin-right: auto;
  margin-left: auto;
}

.app-download-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.app-download-button-ios,
.app-download-button-android {
  display: block;
  width: 128px;
  height: 128px;
  background-size: contain;
  background-repeat: no-repeat;
}

.app-download-button-ios {
  margin-right: 10px;
}

.app-download-button-android {
  margin-left: 10px;
}

.logo-page {
  background-color: #398175;
  display: flex;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  overflow-y: auto;
  padding-bottom: 3rem;
  flex-direction: row !important;
}

.logo-page-login-container {
  display: inline-block;
  width: 50vw;
  height: 100vh;
}

.logo-page-explore-container {
  top: 0;
  position: absolute;
  display: inline-block;
  width: 50vw;
  height: 120vh;
  background-color: #398175;
}

.logo-page-explore {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media (max-width: 720px) {
  .logo-page {
    align-items: center;
    justify-content: center;
    flex-direction: column !important;
  }
  .logo-page-login-container {
    width: 100vw;
  }
  .logo-page-explore-container {
    position: relative;
    width: 100vw;
    height: 110vh;
    height: auto;
    padding-bottom: 10vh;
  }
}
