.urp-image-zoom {
  position: fixed;
  z-index: 10000;
  top: 28px;
  left: 3px;
  right: 3px;
  bottom: 3px;
  background-color: white;
  border-radius: 2px;
  border: 1px solid #808080;
  //background-size: contain;
  //background-repeat: no-repeat;
  //background-position: center;

  &.hide {
    display: none;
  }

  .tools {
    position: absolute;
    z-index: 100000;
    width: 100%;

    .close {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.799);
      text-align: center;
      padding-top: 10px;
      padding-left: 2px;
    }
  }

  .zoom-wrapper {
    height: 100%;
    width: 100%;
  }
}
