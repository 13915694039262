.splash-logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 35vh;
  background-color: #398175;
}

/* Splash Page Backdrop */
.urp-splash-backdrop {
  background: #398175;
  height: 100vh;
  width: 100vw;
  position: absolute;
  left: 0;
  top: 0;
}
