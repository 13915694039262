.post-stats {
  background-color: #f9fbfb;

  .post-stats-content {
    --background: #f9fbfb;
  }

  .urp-post-stats-item {
    --padding-end: 16px;
    font-size: 12px;
    --background: #f9fbfb;
  }

  .urp-post-stats-label {
    color: #1a3a35;
  }

  .urp-post-stats-value {
    color: #1a3a35;
  }

  .urp-post-stats-header-row {
    --padding-start: 0;
    --padding-end: 0;
    font-size: 12px;
    display: flex;
    --background: #f9fbfb;
  }

  .urp-post-stats-header-row > div:nth-child(1) {
    padding-left: 16px;
  }

  .urp-post-stats-header-row > div:nth-child(2) {
    flex: 1;
    padding-right: 16px;
    text-align: right;
  }

  .urp-post-stats-header-select {
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    padding: 5px 24px 5px 8px;
    position: relative;
  }

  .urp-post-stats-header-select ion-icon {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
  }
}
