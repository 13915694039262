.home-header {
  background-color: #ffffff;
  box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.23);
  height: auto;
  min-height: 5rem;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  --opacity-scale: 0 !important;

  .home-header-toolbar {
    --background: #ffffff;
  }

  .home-page .urp-float-logo {
    float: left;
  }

  @media (min-width: 900px) {
    .home-header {
      display: none;
    }
  }
}
