.watchlist-list-item {
  ion-item {
    --padding-start: 0;
    --inner-padding-end: 0;
  }

  ion-item ion-icon {
    color: #c9c9ca;
  }
  .feed-border {
    margin-left: 4px;
    margin-right: 4px;
  }

  .watchlist-item {
    border-radius: 10px;
    // background-color: white;
    --background: #f9fbfb;
    border-bottom: 1px solid #ececec;
    padding: 0 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05) !important;
  }
  .remove-watchlist-item {
    background-color: #bf0a0a !important;
    color: white;
    padding-left: 10px;
    padding-right: 10px !important;
  }
}
