.add-stat-container {
  --height: 575px;
  --border-radius: 10px;
  --backdrop-opacity: 0.1;
  align-items: end;

  .add-stat-content {
    background: white;
  }

  .add-stat-btn {
    height: 2rem !important;
    opacity: 1;
    --background: #94fff3;
    --color: #ffffff;
    --background-activated: #94fff3;
  }

  ion-toolbar.add-stat-header-toolbar {
    --background: #ffffff;
    padding: 0;
  }

  .cancel-btn {
    text-transform: unset;
  }

  .cancel-btn {
    background: none !important;
    color: #9bc9c1 !important;
    height: 2rem !important;
  }

  ion-button.cancel-btn {
    --background: none;
    --color: #ffffff;
    --background-activated: none;
  }

  .add-stat-header {
    text-align: center;
    font-size: 15px;
    color: #1a3a35;
    font-weight: 500;
  }

  .add-stat-section {
    color: #00d6b6;
    font-size: 20px;
    --padding-end: 20px;
    --padding-start: 20px;
    --inner-padding-start: 20px;
  }

  .add-stat-category {
    color: #1a3a35;
    font-size: 15px;
    --padding-end: 20px;
    --padding-start: 20px;
    --inner-padding-start: 20px;
    --border-color: #d6d6d6;

    &.disabled {
      color: #d6d6d6;
    }
  }
}
